import Head from "next/head"
import { useRouter } from "next/router"

export default function Meta({ info }) {
  const siteUrl = process.env.NEXT_PUBLIC_SITE_URL
  const router = useRouter()
  const cleanPath = router.asPath.split("#")[0].split("?")[0].slice(1).replace(".html", "")
  const canonicalUrl = `${siteUrl}` + (router.asPath === "/" ? "" : cleanPath)

  return (
    <Head>
      {info?.title ? <title>{`${info?.title}`}</title> : <title>2060 Digital</title>}
      {info?.description ? <meta name="description" content={info?.description} /> : null}
      {canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : null}
      {info?.title ? <meta property="og:title" content={info?.title} /> : null}
      {
        <meta
          property="og:image"
          content={info?.og_image ? info?.og_image : "https://2060digital.com/assets/2060-placeholder.png"}
        />
      }
      {info?.description ? <meta property="og:description" content={info?.description} /> : null}
      {info?.title ? <meta property="twitter:title" content={info?.title} /> : null}
      {
        <meta
          property="twitter:image"
          content={
            info?.twitter_image
              ? info?.twitter_image
              : "https://a-us.storyblok.com/f/1018933/496x306/8207f09789/2060-placeholder.svg"
          }
        />
      }
      {info?.description ? <meta property="twitter:description" content={info?.description} /> : null}
    </Head>
  )
}
