import Link from "next/link"
import cn from "classnames"
import { getStoryblokLink } from "utilities/getStoryblokLink"
import getTarget from "utilities/getTarget"
import richText from "utilities/richText"
import useWindowSize from "utilities/useWindowSize"
import { useMemo, useState, useEffect } from "react"
import Image from "components/Image"

export default function SingleMenuItem({ item, currentPath, openSubmenu, setOpenSubmenu, open, isNavigating }) {
  const link = item.link ?? item.parent_link
  const href = getStoryblokLink(link)
  const { width } = useWindowSize()
  const [hasMouseLeft, setHasMouseLeft] = useState(false)

  const isOpen = open || openSubmenu === item?.label || openSubmenu === "all"

  const isActiveLink = useMemo(() => {
    return currentPath.replace(/\/$/, "").toLowerCase() === href.replace(/\/$/, "").toLowerCase()
  }, [currentPath, href])

  useEffect(() => {
    if (isNavigating) {
      setOpenSubmenu("")
      // Helps ensure submenu doesn't immediately reopen
      setHasMouseLeft(false)
    }
  }, [isNavigating, setOpenSubmenu])

  const handleMouseLeave = () => {
    if (!isNavigating) {
      setHasMouseLeft(true)
    }
  }

  const handleMouseEnter = () => {
    if (width >= 1240 && (!isActiveLink || hasMouseLeft)) {
      // Don't reopen submenu if the user is navigating / has just navigated
      if (!isNavigating) {
        setOpenSubmenu(item?.label)
      }
    }
  }

  return (
    <li
      className={cn("menu-item group static xl:my-16 hover:xl:pb-16 hover:xl:mb-0 cursor-pointer", {
        "open-submenu": isOpen,
      })}
      onClick={() => setOpenSubmenu(isOpen ? "" : item?.label)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {href ? (
        <Link
          href={href}
          target={getTarget(href)}
          className={cn("top-level link block xl:inline-block cursor-pointer text-white", {
            "current-page": isActiveLink,
          })}
        >
          <span className="whitespace-normal sm:whitespace-nowrap">{item?.label}</span>
        </Link>
      ) : (
        <div
          className="top-level nonlinked select-none cursor-pointer text-white hover:text-green-kelly"
          tabIndex={0}
          onClick={(e) => {
            e.stopPropagation()
            setOpenSubmenu(isOpen ? "" : item?.label)
          }}
        >
          {item?.label}
        </div>
      )}
      <div
        className={cn(
          "submenu w-full xl:drop-shadow-[0_10px_10px_rgba(0,0,0,0.50)] xl:px-6 header-submenu-background",
          {
            "open-submenu": isOpen,
          },
        )}
      >
        <div className="mx-auto w-full max-w-screen-xl 2xl:max-w-screen-2xl xl:px-6 2xl:px-10">
          <div className="w-full flex flex-col xl:flex-row xl:items-start xl:pt-6 flex-1 md:gap-8 xl:gap-24 mx-auto relative">
            <div className="xl:basis-1/2 pb-12">
              {href ? (
                <Link href={href} target={getTarget(href)}>
                  <button onClick={() => setOpenSubmenu(isOpen ? "" : item?.label)}>
                    <h1 className="before:hidden submenu-label hidden xl:block uppercase font-secondary font-bold text-white md:text-green-kelly md:pb-4">
                      {item?.label}
                    </h1>
                  </button>
                </Link>
              ) : (
                <button onClick={() => setOpenSubmenu(isOpen ? "" : item?.label)}>
                  <h1 className="before:hidden submenu-label hidden xl:block uppercase font-secondary font-bold text-white md:text-green-kelly md:pb-4">
                    {item?.label}
                  </h1>
                </button>
              )}
              {width >= 768 && (
                <div className="text-white xl:pl-0 max-w-prose prose-p:pb-6 pointer-events-auto menu-description">
                  {richText(item?.description)}
                </div>
              )}
            </div>
            {width >= 1240 && (
              <div className="pl-6 xl:pl-0 xl:basis-1/2 text-white xl:prose-img:max-w-[472px] xl:prose-img:ml-auto xl:prose-img:mr-0">
                {item.additional_information_image && (
                  <Image
                    src={item.additional_information_image.filename}
                    alt={item.additional_information_image.alt}
                    width={0}
                    height={0}
                    className="max-w-full h-auto w-full mb-4"
                    sizes="(min-width: 1536px) 472px, (min-width: 1240px) 472px"
                    srcSet={`
                      ${item.additional_information_image.filename}?w=472 472w,
                      ${item.additional_information_image.filename}?w=944 944w,
                      ${item.additional_information_image.filename}?w=1416 1416w,
                      ${item.additional_information_image.filename}?w=1888 1888w
                    `}
                  />
                )}
                {richText(item?.additional_information)}
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  )
}
