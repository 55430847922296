import cn from "classnames"
export default function DoubleHeading({ className, heading, heading2 }) {
  if (!heading && !heading2) return
  return (
    <h2 className={cn(className, "font-semibold")}>
      {heading.trim()}
      {heading2 && (
        <>
          {" "}
          <span className="xs:whitespace-nowrap">{heading2.trim()}</span>
        </>
      )}
    </h2>
  )
}
