import cn from "classnames"
import DynamicComponent from ".."

export default function SectionContainer({ blok }) {
  const sectionContainer = cn(
    blok.background_color && `section-container-${blok.background_color}`,
    `section-container`,
  )

  return (
    <div className={sectionContainer}>
      {blok.sections.map((section, i) => (
        <section key={`${blok._uid}-${i}`} className="container-section">
          <DynamicComponent blok={section} />
        </section>
      ))}
    </div>
  )
}
