import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel"
import DoubleHeading from "components/DoubleHeading"
import Arrow from "/public/assets/arrow-button.svg"
import { useState } from "react"
import richText from "utilities/richText"
import "pure-react-carousel/dist/react-carousel.es.css" // Make sure to include this if you haven't already
import Image from "components/Image"

export default function TestimonialSection({ blok }) {
  const [selectedSlide, setSelectedSlide] = useState(0)
  const nextTestimonial = () =>
    selectedSlide < blok.testimonials.length - 1 ? setSelectedSlide(selectedSlide + 1) : setSelectedSlide(0)

  const prevTestimonial = () =>
    selectedSlide !== 0 ? setSelectedSlide(selectedSlide - 1) : setSelectedSlide(blok.testimonials.length - 1)

  return (
    <section className="py-12 pb-16">
      <div className="container mx-auto">
        {/* SVG Gradient Definition used for Arrow Buttons */}
        <svg width="0" height="0">
          <defs>
            <linearGradient id="svg-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" style={{ stopColor: "#40B449", stopOpacity: 1 }} />
              <stop offset="100%" style={{ stopColor: "#C4D82E", stopOpacity: 1 }} />
            </linearGradient>
          </defs>
        </svg>

        <div className="mx-auto max-w-screen-lg">
          <div className="lg:max-w-2xl pb-4 relative z-10 text-center mx-auto">
            {blok.eyebrow && <span className="eyebrow eyebrow-2">{blok.eyebrow}</span>}
            <DoubleHeading className="mb-1" heading={blok.heading} />
          </div>
          <div>
            <CarouselProvider
              isIntrinsicHeight
              totalSlides={blok.testimonials.length}
              visibleSlides={1}
              infinite
              currentSlide={selectedSlide}
              className="sm:mx-auto relative"
            >
              <Slider className="md:w-full mx-auto overflow-hidden carousel">
                {blok.testimonials.map((testimonial, i) => (
                  <Slide key={i} index={i} className="lg:px-20 carousel__slide">
                    <button onClick={() => setSelectedSlide(blok.testimonials.indexOf(testimonial))}>
                      {testimonial.image && testimonial.image.filename && (
                        <Image
                          className="rounded-2xl mx-auto mb-2 aspect-square object-cover"
                          width="85"
                          height="85"
                          src={testimonial.image.filename}
                          alt={testimonial.image.alt ?? "Testimonial image"}
                        />
                      )}
                      <div className="text-left lg:text-center prose-p:lg:text-m3">{richText(testimonial.quote)}</div>
                      <div className="text-left lg:text-center mt-3 prose-p:text-sm prose-p:lg:text-m1">
                        - {testimonial.citation}
                      </div>
                    </button>
                  </Slide>
                ))}
              </Slider>
              <div className="pt-4 max-w-36 mx-auto md:pt-0 lg:max-w-none lg:absolute inset-y-0 -top-16 left-0 flex items-center justify-between w-full px-2 lg:px-0">
                <ButtonBack onClick={() => prevTestimonial()}>
                  <Arrow className="text-secondary hover:text-tertiary hover:scale-105 arrow-svg-gradient hover:fill-blue-bright" />
                </ButtonBack>
                <ButtonNext onClick={() => nextTestimonial()}>
                  <Arrow className="rotate-180 text-secondary hover:scale-105 arrow-svg-gradient hover:fill-blue-bright" />
                </ButtonNext>
              </div>
            </CarouselProvider>
          </div>
        </div>
      </div>
    </section>
  )
}
