import richText from "utilities/richText"
import Stat from "./Stat"
import cn from "classnames"
import Image from "components/Image"
import getImageDimensions from "utilities/getImageDimensions"

export default function Card({ blok, className }) {
  const headingImage = blok.heading_image
  return (
    <div
      className={cn(
        className,
        `h-full relative pointer-events-none before:rounded-4xl before:top-0 before:left-0 rounded-4xl before:content-[" "] before:absolute before:w-full before:h-full before:bg-warm-white/70 before:mix-blend-overlay before:border-2 border-solid before:border-white p-6 py-8 2xl:py-12 text-center`,
      )}
    >
      {headingImage?.filename && (
        <div className="flex justify-center">
          <div className="pt-4 pb-6 w-full max-h-36 relative z-20">
            <Image
              className="max-h-full max-w-full h-auto w-auto mx-auto"
              height={getImageDimensions("height", headingImage.filename)}
              width={getImageDimensions("width", headingImage.filename)}
              src={headingImage.filename}
              alt={headingImage.alt}
            />
          </div>
        </div>
      )}
      <h3
        className={
          headingImage?.filename ? `sr-only` : `uppercase leading-none mb-1 2xl:mb-4 font-semibold tracking-wider`
        }
      >
        {blok.heading}
      </h3>
      {richText(blok.content)}
      {blok.stats?.map((stat) => (
        <Stat key={`${blok._uid}_${stat._uid}`} blok={stat} />
      ))}
    </div>
  )
}
