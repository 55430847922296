import CallToAction from "components/CallToAction"
import { getStoryblokLink } from "utilities/getStoryblokLink"
import richText from "utilities/richText"
import PlusPattern from "public/assets/decorative-patterns/plus-pattern.svg"
import LinedCircle from "public/assets/decorative-patterns/lined-circle.svg"

export default function CTABanner({ heading, content, buttonLink, buttonText, asideText }) {
  return (
    <section className="cta-banner-section bg-kelly-to-lime overflow-hidden" data-pagefind-ignore>
      <div className="relative mx-auto max-w-screen-2xl pt-4 lg:pt-0">
        <LinedCircle className="absolute -top-8 -right-6 z-0 size-36 lg:size-64 fill-green-lime" />
        <div className="py-12 lg:py-16 z-10 relative">
          <div className="container mx-auto flex max-w-screen-xl 2xl:max-w-screen-2xl flex-col items-center">
            <h2 className="text-center pt-16 pb-2 xl:pt-0 text-l2 font-bold text-blue xl:text-xl3 relative capitalize">
              <span className="absolute -top-6 left-1/2 -translate-x-1/2 xl:-left-24 xl:top-0 max-w-32 font-tertiary text-blue text-l1 rotate-callout before:content-[''] before:bg-cta-callout-arrow before:w-16 before:h-12 before:block before:bg-no-repeat before:-left-12 before:top-8 before:absolute xl:before:bg-none xl:after:content-[''] xl:after:bg-cta-callout-arrow  xl:after:bg-no-repeat xl:after:absolute xl:after:h-12 xl:after:w-16 xl:after:-rotate-[104.7deg] xl:after:top-12 xl:after:-right-6">
                {asideText}
              </span>
              {heading}
            </h2>
            <div className="prose-p:text-m2 pb-6 prose-p:lg:text-m3 text-blue prose-p:font-semibold text-center max-w-lg">
              {richText(content)}
            </div>
            <CallToAction style="tertiary" href={getStoryblokLink(buttonLink)}>
              {buttonText}
            </CallToAction>
          </div>
        </div>
        <PlusPattern className="z-0 absolute fill-green-lime size-64 -left-20 -bottom-20 xl:-bottom-48 xl:-left-32 lg:size-72 xl:size-96 " />
      </div>
    </section>
  )
}
