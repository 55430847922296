import storyblokImageLoader from "utilities/storyblokImageLoader"
import Image from "./Image"

export default function PhotoBean({ image }) {
  return (
    <div className="w-full">
      <div className="flex-0 xl:pr-0 flex justify-center lg:justify-end rotate-8 overflow-x-hidden">
        {image && (
          <div className="w-full relative flex justify-center lg:justify-end">
            <div className="relative">
              <div className="photo-bean">
                <div className="masked-image mt-8 xl:mt-10 z-50 relative w-full h-full">
                  <Image
                    loader={storyblokImageLoader}
                    alt={image.alt}
                    src={image.filename}
                    fill
                    priority
                    sizes="(min-width: 1536px) 592px, 450px"
                    className="bg-purple-gradient object-cover"
                  />
                </div>
                <svg
                  className="fill-green-lime absolute top-0 right-0 z-20 m-auto w-[84%] h-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  width="494"
                  height="498"
                  viewBox="0 0 494 494"
                  fill="none"
                >
                  <path
                    d="M286.19 27.454C345.507 68.9267 339.39 63.7152 395.431 69.2102C412.177 70.854 428.21 77.0841 441.425 87.4994C464.873 105.98 482.464 133.253 490.527 160.987C515.035 262.698 401.715 251.935 414.567 346.687C419.603 379.015 442.866 409.736 434.676 441.41C425.778 475.843 385.177 490.411 349.918 494.771C261.111 502.023 165.931 494.636 80.709 466.516C73.5625 464.156 66.7204 460.916 60.4295 456.78C3.26659 419.141 -12.2599 338.924 9.48615 275.777C16.1789 254.564 26.0499 234.404 31.6859 212.883C36.6788 193.809 38.2576 174.034 41.4066 154.567C41.721 152.633 41.9464 150.682 42.1085 148.731C52.2296 26.6931 172.992 -35.1075 278.188 22.46C280.948 23.9689 283.61 25.6506 286.19 27.454Z"
                    fill="#C4D82E"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
