import { useState, useRef, useEffect } from "react"
import PlayButton from "public/assets/play.svg"
import cn from "classnames"
import useWindowSize from "utilities/useWindowSize"
import Image from "./Image"
import storyblokImageLoader from "utilities/storyblokImageLoader"

export default function HeroVideo({ video_asset, video_fallback, thumbnail }) {
  const [videoPlaying, setVideoPlaying] = useState(true)
  const videoRef = useRef(null)
  const { width } = useWindowSize()

  let type = `video/${video_asset?.filename?.match(/.(mov|mp4|webm)/gi)[0]}`.replace(".", "")

  switch (type) {
    case "video/mov":
      type = "video/mp4"
      break
    case "video/mp4":
      type = "video/mp4"
      break
    case "video/webm":
      type = "video/webm"
      break
    default:
      type = "invalid"
      break
  }

  let fallbackType = `video/${video_fallback?.filename?.match(/.(mov|mp4|webm)/gi)[0]}`.replace(".", "")

  switch (fallbackType) {
    case "video/mov":
      fallbackType = "video/mp4"
      break
    case "video/mp4":
      fallbackType = "video/mp4"
      break
    case "video/webm":
      fallbackType = "video/webm"
      break
    default:
      type = "invalid"
      break
  }

  return (
    <div className="relative mx-auto h-full">
      <button
        onClick={() => {
          setVideoPlaying(!videoPlaying)
          videoPlaying === true ? videoRef.current.pause() : videoRef.current.play()
        }}
        className={cn("cursor-pointer absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10", {
          hidden: videoPlaying,
        })}
        title="Play video"
      >
        <PlayButton className="play-video" />
      </button>

      <Image
        src={thumbnail?.filename}
        alt="Video Thumbnail"
        fill
        loader={storyblokImageLoader}
        priority={true}
        className="object-cover"
        sizes="(min-width: 1536px) 698px, (min-width: 1240px) 600px, 470px"
      />

      <video
        ref={videoRef}
        className="scale-[180%] translate-y-20 xl:translate-y-24 video-embed w-[full] aspect-video"
        controls={false}
        muted
        playsInline={true}
        loop
        autoPlay={true}
        preload="metadata"
        onPlay={() => setVideoPlaying(true)}
        onPause={() => setVideoPlaying(false)}
      >
        <source src={video_asset?.filename} type={type} />
        {video_fallback && <source src={video_fallback?.filename} type={fallbackType} />}
      </video>
    </div>
  )
}
