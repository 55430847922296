import { useState, useEffect } from "react"
import { useRouter } from "next/router"
import useWindowSize from "utilities/useWindowSize"
import Link from "next/link"
import cn from "classnames"
import { motion } from "framer-motion"
import SingleMenuItem from "components/Header/SingleMenuItem"
import CallToAction from "components/CallToAction"
import Logo from "public/assets/logo.svg"
import MenuButton from "public/assets/hamburger-menu.svg"
import { getStoryblokLink } from "utilities/getStoryblokLink"

const mobileMenuVariants = {
  open: {
    opacity: 1,
    height: "100vh",
    transition: {
      duration: 0.75,
    },
  },
  closed: {
    opacity: 0,
    height: "0vh",
    transition: {
      duration: 0.5,
    },
  },
}

const desktopMenuVariants = {
  open: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
  closed: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
}

export default function Header({ menu }) {
  const [open, setMobileMenuVisibility] = useState(false)
  const [openSubmenu, setOpenSubmenu] = useState("")
  const [isNavigating, setIsNavigating] = useState(false)
  const router = useRouter()
  const width = useWindowSize().width

  useEffect(() => {
    if (width >= 1240) {
      setOpenSubmenu("")
      setMobileMenuVisibility(false)
    }
  }, [width])

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setIsNavigating(true)
    }

    const handleRouteChangeComplete = () => {
      setOpenSubmenu("")
      setMobileMenuVisibility(false)
      setTimeout(() => setIsNavigating(false), 100) // Small delay to ensure transition
    }

    router.events.on("routeChangeStart", handleRouteChangeStart)
    router.events.on("routeChangeComplete", handleRouteChangeComplete)
    router.events.on("hashChangeComplete", handleRouteChangeComplete)

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart)
      router.events.off("routeChangeComplete", handleRouteChangeComplete)
      router.events.off("hashChangeComplete", handleRouteChangeComplete)
    }
  }, [router.events])

  useEffect(() => {
    if (open) {
      document.querySelector("html").style.overflow = "hidden"
      setOpenSubmenu("all")
    } else {
      document.querySelector("html").style.overflow = ""
      setOpenSubmenu("")
    }
    if (width >= 1240) setOpenSubmenu("")
  }, [open, width])

  return (
    <header className="bg-dark-blue-to-blue relative" onMouseLeave={() => (width >= 1240 ? setOpenSubmenu("") : null)}>
      <div
        className={cn("container max-w-screen-xl 2xl:max-w-screen-2xl mx-auto xl:flex items-center justify-between", {
          "before:content-[''] before:bg-no-repeat before:bg-circle-lines before:w-40 before:h-48 before:block before:absolute before:left-0 before:bottom-20 xl:before:hidden after:content-[''] after:bg-no-repeat after:bg-plus-pattern after:w-36 after:h-80 after:block after:absolute after:right-0 after:bottom-0 xl:after:hidden":
            (open && !openSubmenu?.length) || openSubmenu === "all",
          "h-[100dvh] xl:h-auto": open,
        })}
      >
        <div className="flex items-center justify-between w-full xl:w-auto border-b border-b-white xl:border-b-0 py-5 xl:py-0 z-20">
          <Link
            aria-label="2060 Digital Home"
            className="mt-3 xl:mt-2"
            href="/"
            onMouseEnter={() => setOpenSubmenu("")}
          >
            <Logo alt="2060 Digital Logo" title="2060 Digital" className="h-auto w-[215px] 2xl:w-[260px]" />
          </Link>
          <button
            className="xl:hidden hamburger-btn"
            aria-label={open ? `close menu` : `open menu`}
            onClick={() => setMobileMenuVisibility((prev) => !prev)}
          >
            <MenuButton className={cn("mobile-menu-btn cursor-pointer", { open })} />
          </button>
        </div>
        <motion.div
          initial={false}
          animate={open || width >= 1240 ? "open" : "closed"}
          variants={!width || width >= 1240 ? desktopMenuVariants : mobileMenuVariants}
          className={cn(
            "xl:p-0 w-full h-full xl:h-auto xl:w-auto relative xl:static z-50 flex flex-col xl:flex-row items-start xl:items-center gap-6 xl:gap-0 max-h-0 xl:max-h-none",
            {
              "max-h-none": width >= 1240,
              "hidden xl:flex": !open && width < 1240,
              "max-h-none py-12": open,
            },
          )}
        >
          <nav
            aria-label="Main"
            className="menu flex flex-col xl:flex-row xl:items-center h-full w-full overflow-y-scroll overflow-x-hidden pb-20 lg:pb-0"
          >
            <ul
              aria-label="Top-level Menu Items"
              className="m-0 pb-8 xl:pb-0 xl:flex items-start md:gap-y-8 md:gap-x-12 lg:gap-x-16 xl:gap-4 2xl:gap-6 md:grid md:grid-cols-2"
            >
              {menu?.menu_items?.map((item) => (
                <SingleMenuItem
                  currentPath={router?.asPath}
                  item={item}
                  key={`menu-item-${item?._uid}`}
                  {...{ openSubmenu, setOpenSubmenu, open, isNavigating }}
                />
              ))}
            </ul>
            <span onMouseEnter={() => setOpenSubmenu("")} className="mt-1">
              <CallToAction className="mx-auto w-full xl:w-auto" href={`/${getStoryblokLink(menu?.contact_link)}`}>
                {menu?.contact_link_label}
              </CallToAction>
            </span>
          </nav>
        </motion.div>
        <div
          className={cn(
            "hidden mx-auto xl:block xl:absolute left-1/2 -translate-x-1/2 border-b-[1px] bottom-0 border-white w-full h-full",
            {
              "xl:max-w-[calc(theme(screens.xl)-theme(spacing.12))] 2xl:max-w-[calc(theme(screens.2xl)-theme(spacing.24))]":
                !openSubmenu,
            },
          )}
        ></div>
      </div>
    </header>
  )
}
