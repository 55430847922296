import Link from "next/link"
import { getStoryblokLink } from "utilities/getStoryblokLink"
import CallToAction from "components/CallToAction"
import getTarget from "utilities/getTarget"
import Logo from "public/assets/logo.svg"
import Facebook from "public/assets/social-icons/facebook.svg"
import Instagram from "public/assets/social-icons/instagram.svg"
import LinkedIn from "public/assets/social-icons/linkedin.svg"
import TikTok from "public/assets/social-icons/tiktok.svg"
import getTelLink from "utilities/getTelLink"

export default function Footer({ footer, colophon, socialMedia, contactInfo }) {
  return (
    <footer>
      <section className="bg-dark-blue-to-blue py-12">
        <div className="container max-w-screen-xl 2xl:max-w-screen-2xl mx-auto flex flex-col md:flex-row justify-between gap-12">
          <div className="flex flex-col gap-5">
            <Link aria-label="2060 Digital Home" href="/">
              <Logo className="2xl:w-64 h-auto mt-1 max-w-64" />
            </Link>
            <div className="flex flex-col items-start gap-2">
              <CallToAction href={getTelLink(contactInfo?.phone)} target="_self" style="phone">
                {contactInfo?.phone}
              </CallToAction>
              <CallToAction href={`mailto:${contactInfo?.email}`} target="_self" style="email">
                {contactInfo?.email}
              </CallToAction>
            </div>
            <div className="flex gap-4 items-center">
              <Link href={socialMedia?.facebook || "#"} target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                <Facebook />
              </Link>
              <Link href={socialMedia?.linkedin || "#"} target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                <LinkedIn />
              </Link>
              <Link
                href={socialMedia?.instagram || "#"}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <Instagram />
              </Link>
              <Link href={socialMedia?.tiktok || "#"} target="_blank" rel="noopener noreferrer" aria-label="TikTok">
                <TikTok />
              </Link>
            </div>
          </div>
          {footer && (
            <>
              <div key={footer[0]?._uid}>
                <h3 className="text-green-kelly pb-5 uppercase 2xl:text-l1">{footer[0]?.label}</h3>
                <div className="columns-2 lg:grid lg:grid-flow-col grid-rows-5 lg:grid-cols-[1fr_auto_auto] gap-x-16">
                  {footer[0]?.nested_menu_items?.map((item) => {
                    const href = getStoryblokLink(item?.link)
                    const target = getTarget(href)
                    return (
                      <Link
                        href={href}
                        target={target}
                        rel={target === "_self" ? null : "nooopener noreferrer"}
                        className="text-white font-semibold text-base 2xl:text-m2 hover:underline block pb-2"
                        key={item?._uid}
                      >
                        {item?.label}
                      </Link>
                    )
                  })}
                </div>
              </div>
              <div key={footer[1]?._uid} className="md:mr-12 lg:mr-24">
                <h3 className="text-green-kelly pb-5 2xl:text-l1 uppercase">{footer[1]?.label}</h3>
                <div className="columns-2 md:columns-1">
                  {footer[1]?.nested_menu_items?.map((item) => {
                    const href = getStoryblokLink(item?.link)
                    const target = getTarget(href)
                    return (
                      <Link
                        href={href}
                        target={target}
                        rel={target === "_self" ? null : "nooopener noreferrer"}
                        className="text-white font-bold text-base 2xl:text-m2 hover:underline block pb-2"
                        key={item?._uid}
                      >
                        {item?.label}
                      </Link>
                    )
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <section className="bg-blue py-2.5">
        <div className="container mx-auto flex max-w-screen-xl 2xl:max-w-screen-2xl flex-wrap items-center justify-center gap-x-24 gap-y-4 xl:justify-between">
          <div className="fine-print text-center text-white lg:text-left">
            © {new Date().getFullYear()} 2060 Digital | All Rights Reserved.
          </div>
          <ul className="flex flex-wrap justify-center gap-1 items-center">
            {colophon?.map((item) => {
              const href = getStoryblokLink(item?.link)
              const target = getTarget(href)
              return (
                <li
                  key={item?._uid}
                  className="after:content-['|'] after:block last:after:hidden flex items-center text-white gap-1 "
                >
                  <Link
                    href={href || "#"}
                    className="text-white fine-print hover:underline"
                    target={target}
                    rel={target === "_self" ? null : "noopener noreferrer"}
                  >
                    {item?.label}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </section>
    </footer>
  )
}
