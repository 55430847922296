import CTABanner from "components/CTABanner"

export default function CTABannerDynamic({ blok }) {
  return (
    <CTABanner
      content={blok.content}
      asideText={blok.aside_text}
      buttonLink={blok.button_link}
      buttonText={blok.button_text}
      heading={blok.heading}
    />
  )
}
