export default function storyblokImageLoader({ src, width, quality = 75 }) {
  if (src.includes(".svg")) {
    // Ensure that even SVGs get a width applied (avoids next/image warning)
    return `${src}?width=${width}`
  }
  return `${src}/m/${width}x0/smart/filters:quality(${quality})`
}

export function centerCropImageLoader({ src, width, quality = 75 }) {
  return `${src}/m/${width}x${width}/smart/filters:crop_center,square,quality(${quality})`
}
