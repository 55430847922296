import NextLink from "next/link"
import cn from "classnames"
import { motion } from "framer-motion"
import getTarget from "utilities/getTarget"
import { useCallback } from "react"

export default function CallToAction({
  href,
  target = null,
  children,
  style = "primary",
  button = false,
  download = false,
  className,
  title = null,
  onClick,
}) {
  const styles = cn(
    "cursor-pointer max-w-screen-sm text-center relative overflow-hidden", // default styles
    { "inline-block": style !== "inline", inline: style === "inline" },
    `${style}-link`,
    className,
  )

  const renderCTA = () => {
    if (button) {
      return (
        <button className={styles} title={title} onClick={onClick}>
          <span className="relative z-10">{children}</span>
        </button>
      )
    }

    if (!href)
      return (
        <span data-type="invalid-link" className={styles}>
          <span className="relative z-10">{children}</span>
        </span>
      )

    const targetValue = target ?? getTarget(href)
    return targetValue === "_self" ? (
      <NextLink
        href={href}
        data-type="route"
        target="_self"
        title={title}
        download={download}
        className={styles}
        onClick={onClick}
      >
        <span className="relative z-10">{children}</span>
      </NextLink>
    ) : (
      <a
        data-type="external"
        className={styles}
        target={targetValue}
        title={title}
        download={download}
        href={href}
        onClick={onClick}
      >
        <span className="relative z-10">{children}</span>
      </a>
    )
  }

  if (style === "tertiary")
    return (
      <motion.div
        className="cta"
        variants={{
          rest: { scale: 1 },
          hover: { scale: 1.05 },
          pressed: { scale: 0.9 },
        }}
        initial="rest"
        whileHover="hover"
        whileTap="pressed"
        transition={{
          duration: 50,
          type: "spring",
          stiffness: 200,
          bounce: 0.75,
        }}
      >
        {renderCTA()}
      </motion.div>
    )
  return renderCTA()
}
