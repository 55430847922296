import Head from "next/head"
import Script from "next/script"
import { useRouter } from "next/router"
import React from "react"
import { useNextRouterViewTransitions } from "use-view-transitions/next"

import "styles.css"
import "@fontsource/karla/400.css"
import "@fontsource/karla/500.css"
import "@fontsource/karla/600.css"
import "@fontsource/karla/700.css"
import "@fontsource/karla/800.css"

import PreviewAlert from "components/PreviewAlert"
import Header from "components/Header"
import Footer from "components/Footer"

import useStoryblok from "storyblok/useStoryblok"
import { resolve_relations } from "storyblok/resolveRelations"

function MyApp({ Component, pageProps: { preview, story, globals, ...remainingProps } }) {
  story = useStoryblok({ story, resolve_relations })
  const router = useRouter()
  useNextRouterViewTransitions(router)

  return (
    <>
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#603cba" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      {preview && <PreviewAlert />}
      <Header menu={globals?.header} />

      <Component story={story} {...remainingProps} />

      <Footer
        footer={globals?.footer}
        colophon={globals?.colophon}
        socialMedia={globals?.socialMedia}
        contactInfo={globals?.contactInfo}
      />
      <Script type="application/ld+json" id="organization-schema">
        {`{
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://www.2060digital.com",
          "sameAs": [${Object?.values(globals?.socialMedia).map(
            (link, idx) => `"${link}"${Object?.values(globals?.socialMedia).length - 1 < idx ? `, ` : ``}`,
          )}],
          "name" : "2060 Digital",
          "telephone": "${globals?.contactInfo?.phone}",
          "email": "${globals?.contactInfo.email}"
        }`}
      </Script>
    </>
  )
}

export default MyApp
