import cn from "classnames"

import richText from "utilities/richText"
import PhotoBean from "./PhotoBean"
import HeroVideo from "./HeroVideo"
import Blob from "public/assets/blob-hero.svg"

export default function PageHeaderFull({
  className,
  heading,
  content,
  eyebrow,
  image,
  extra_bottom_padding = false,
  slight_bottom_padding = false,
}) {
  return (
    <header
      id="full-page-header"
      className={cn(
        "bg-dark-blue-to-blue-inverted text-white pt-8 sm:pt-16 lg:pt-8 relative pb-48",
        { "lg:pb-32 xl:pb-36": extra_bottom_padding },
        { "lg:pb-28 xl:pb-24": slight_bottom_padding },
        { "lg:pb-8": !extra_bottom_padding && !slight_bottom_padding },
        className,
      )}
    >
      <div className="flex flex-col lg:flex-row justify-between max-w-screen-xl 2xl:max-w-screen-2xl m-auto gap-4 relative z-10">
        <div className="container lg:w-1/2 relative z-0 flex-col flex justify-center items-start mx-auto pb-8 lg:pb-0">
          {eyebrow && <span className="eyebrow eyebrow-1 text-green-kelly mb-1">{eyebrow}</span>}
          <h1 className="text-white text-l2 md:text-xl1 lg:text-xl2 xl:text-xl3 font-semibold mb-6">{heading}</h1>
          {content && (
            <div className="text-white prose 2xl:max-w-[80ch] prose-p:text-m2 xl:prose-p:text-m3 2xl:prose-p:text-l1">
              {richText(content)}
            </div>
          )}
        </div>
        <div className="flex flex-col lg:pr-6 2xl:pr-12 lg:w-1/2 absolute w-full top-full lg:relative z-50">
          <PhotoBean image={image} />
        </div>
      </div>
    </header>
  )
}
