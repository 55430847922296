import richText from "utilities/richText"

export default function Stat({ blok }) {
  return (
    <div className="my-3">
      <strong className="text-l2 inline-block mx-2 align-middle font-semibold font-primary">{blok.number}</strong>
      <span className="uppercase inline-block align-middle text-sm font-semibold">{richText(blok.description)}</span>
    </div>
  )
}
