import richText from "utilities/richText"
import HeroVideo from "components/HeroVideo"
import Blob from "public/assets/blob-hero.svg"
import Image from "components/Image"
import storyblokImageLoader from "utilities/storyblokImageLoader"

const svgMask = `url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjM5IiBoZWlnaHQ9IjU0OSIgdmlld0JveD0iMCAwIDYzOSA1NDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgo8cGF0aCBkPSJNMi4wMTM3OSAzMjYuMDc4Qy0yLjMxMTA4IDI5Mi40NjYgMC41MzU3OTkgMjYwLjM4OSAxMC45MDUyIDIzNC4zNjlDNzEuMjU1MSA4Mi43OTk4IDE5My4xNDYgODEuNzE0MSAyODIuMDI0IDc0LjEyNTdDMzMxLjkzMyA2OS44NjAxIDM3NC4xMTcgMzguNDIwNCA0MTguMjU0IDE4LjEwODZDNDI0LjQ5OSAxNS4yMzIgNDMwLjc4MyAxMi41ODgxIDQzNy4xMzQgMTAuMjY1M0M0NzIuNzIzIC0yLjczOTU3IDUxMC40MDYgLTUuMzQyNzIgNTU1LjAwNiAyMS41NjYzQzYwMC42MSA0OS4wODM3IDYyOS45NTQgOTkuMTQyOCA2MzYuNzY4IDE1OC42NjhDNjQxLjEzNSAxOTYuODUyIDYzNi4yMzMgMjM4LjkzNSA2MjAuMzg0IDI4MS40NTdDNjE0LjY0NyAyOTYuNzkxIDYwNy43NCAzMTEuNjY0IDU5OS43MzMgMzI1Ljk0N0M1NzMuNjYgMzcyLjY0MSA1MzMuNjEgNDE4LjM5NyA0NzcuMzk4IDQ1OC42MzhDNDUyLjQzNiA0NzYuMzkgNDI2LjA4MSA0OTIuMTI0IDM5OC41ODcgNTA1LjY4MkMzOTIuNyA1MDguNjA4IDM4Ni43NDcgNTExLjQ5NyAzODAuNjE3IDUxNC4zMTJDMzE2LjA2MSA1NDMuOTg4IDI1OS4yMSA1NTIuNTkyIDIxMC40MTIgNTQ2LjcxNkMxODAuNzYxIDU0My4yNSAxNTIuMTA5IDUzMy44MDcgMTI2LjIyMSA1MTguOTU2QzUzLjY0NzYgNDc3LjI1NCAxMS4yODE3IDM5OC4xNDggMi4wMTM3OSAzMjYuMDc4WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==')`

export default function VideoHero({ content, video, image }) {
  return (
    <header id="video-header" className="bg-dark-blue-to-blue-inverted text-white pt-8 relative pb-8 md:pb-16 lg:pb-0">
      <div className="container mx-auto">
        <div className="flex flex-col-reverse lg:flex-row justify-between max-w-screen-xl 2xl:max-w-screen-2xl m-auto gap-4 relative z-10">
          <div className="flex flex-col lg:w-1/2 w-full lg:relative z-50 order-1 lg:order-2">
            <div className="w-full">
              <div className="flex-0 xl:pr-0 flex justify-center lg:justify-end rotate-8">
                {video && (
                  <div className="w-full relative flex justify-center lg:justify-end">
                    <div className="relative overflow-visible">
                      <div
                        className="masked-video -mb-56 md:-mb-48 lg:-mb-8 xl:mt-0 z-50 relative w-[470px] h-[404px] xl:w-[600px] xl:h-[516px] 2xl:w-[698px] 2xl:h-[600px]"
                        style={{
                          WebkitMaskImage: svgMask,
                          maskImage: svgMask,
                        }}
                      >
                        {video && <HeroVideo component="video_embed" {...video[0]} />}
                      </div>
                      <div className="absolute top-2 md:top-4 xl:top-0 right-[8%] z-20 m-auto w-[95%] h-[95%]">
                        <Blob className="w-full h-full" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="lg:w-1/2 mt-6 relative z-0 flex-col flex justify-center items-start mx-auto prose pb-4 lg:pb-16 order-2 lg:order-1">
            {image && image.filename && (
              <Image
                loader={storyblokImageLoader}
                alt={image.alt}
                src={image.filename}
                width={516}
                height={115}
                priority
                sizes="(min-width: 1536px) 592px, 450px"
              />
            )}
            {content && (
              <div className="text-white lg:pr-16 prose max-w-screen-xs lg:max-w-none prose-p:font-light prose-h1:text-white prose-img:max-w-full prose-img:m-0 prose-img:mt-2 md:prose-img:mt-4 prose-img:mb-4 md:prose-img:mb-6 prose-p:text-m2 xl:prose-p:text-m3 2xl:prose-p:text-l1">
                {richText(content)}
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}
